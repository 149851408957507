import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import Layout from "../../../Layout/Layout";
import Seo from "../../../components/Seo/Seo";
import TitleSection from "../../../Molecules/TitleSection/TitleSection";
import './styles.scss';
import { removeHtmlTag } from "../../../Utils/Utils";
import { graphql } from 'gatsby';

const ReactPDF = (props) => {

  const [title, setTitle] = useState('');
  const [cleanFile, setCleanFile] = useState(null);
  const imagesArray = props?.data.allImages?.edges;

  String.prototype.escape = function () {
    var tagsToReplace = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;'
    };
    return this.replace(/[&<>]/g, function (tag) {
      return tagsToReplace[tag] || tag;
    });
  };

  const queryParams = new URLSearchParams(props.location.search);
  let file = queryParams.get("file")?.escape() || null;
  const file_title = queryParams.get("title")?.escape() || '';
  const description = queryParams.get("description")?.escape() || 'Colas';
  const isValidDomain = (url) => {
    if (url) {
      return url.startsWith(process.env.GATSBY_CDN_BASEURL_ENDPOINT);
    }
  };

  if (file) { file += '#view=fit&toolbar=1&navpanes=0' }

  useEffect(() => {
    const purify = DOMPurify(window);
    const clean_file = purify.sanitize(file);
    const clean_title = purify.sanitize(file_title);
    setCleanFile(clean_file);
    setTitle(clean_title);
  }, [])

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={'Colas - Document PDF'}
        description={removeHtmlTag(description) || 'Colas'}
      />
      <section className="section_content section_content_iframe">
        {!title &&
          <h1 className="visually-hide">Colas - Document PDF</h1>
        }
        <div className="wrapper_page_xs">
          <TitleSection title={title ? title.trim() : ''} />
        </div>
        <div className="wrapper_page">
          {
            isValidDomain(cleanFile) && cleanFile &&
            <div className="outer_pdf">
              <div className="desktop_pdf">
                <iframe id="iframepedf_desktop" type='application/pdf' scrolling="auto" src={cleanFile} width="100%" height="1000px" frameBorder="0" allowtransparency="true" style={{ 'background': '#FFFFFF' }}> </iframe>
              </div>
              <div className="mobile_pdf">
                <iframe id="iframepedf_mobile" scrolling="auto" src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${cleanFile}`} width="100%" type='application/pdf' title="PDF File">
                  <p><em>There is content being displayed here that your browser doesn't support.</em> <a href={cleanFile} target="_blank"> Please click here to attempt to view the information in a separate browser window. </a> Thanks for your patience!</p>
                </iframe>
              </div>
            </div>

          }

        </div>
      </section>

    </Layout>
  );
};


export const query = graphql`
  query PdfPageTemplate(
    $imagesID: [Int!]
  ) {
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
  }
`;

export default ReactPDF;